import { NavItemConfig } from '../../constants/nav';

export function isNavItemActive({ disabled, external, href, matcher, pathname }: Pick<NavItemConfig, 'disabled' | 'external' | 'href' | 'matcher'> & { pathname: string }): boolean {
    if (disabled || !href || external) {
        return false;
    }

    const communityPaths = ['/community/challenges', '/community/points', '/community/posts', '/community/pointexchange'];

    // Check if the pathname matches any of the community paths
    if (communityPaths.includes(pathname) && href.startsWith('/community')) {
        return true;
    }

    if (matcher) {
        if (matcher.type === 'startsWith') {
            return pathname.startsWith(matcher.href);
        }

        if (matcher.type === 'equals') {
            return pathname === matcher.href;
        }

        return false;
    }

    // Handle dynamic segment matching for /mychannel/:id and similar patterns
    if (href.startsWith('/mychannel')) {
        const regex = new RegExp(`^/mychannel(/[^/]+)?(/edit)?$`);
        return regex.test(pathname);
    }

    return pathname === href;
}
